import {IMAGE_TYPES} from "../constants";


/**
 * Retrieves the image URL based on the given payload and type.
 * @param {object} payload - The image payload.
 * @param {string} type - The image type.
 * @returns {string} - The image URL.
 */
const mapImageData = (payload, type) => {
    const {data: {attributes: {formats, url}}} = payload;
    switch (type) {
        case IMAGE_TYPES.FULL:
            return url;
        default:
            const {[`${type}`]: remove} = formats;
            const {url: formatURL} = remove;
            return formatURL;
    }
}

/**
 * Extracts the necessary information from an image object and returns an object with the required format data.
 *
 * @param {Object} image - The image object.
 * @param {Object} image.data - The data attribute of the image object.
 * @param {Object} image.data.attributes - The attributes of the image object data.
 * @param {string} image.data.attributes.caption - The caption of the image.
 * @param {number} image.data.attributes.width - The width of the image.
 * @param {number} image.data.attributes.height - The height of the image.
 * @param {Object} image.data.attributes.formats - The formats attribute of the image object data.
 * @param {string} image.data.attributes.url - The URL of the image.
 * @returns {Object} - An object containing the extracted image data.
 * @property {string} caption - The caption of the image.
 * @property {number} aspectRatio - The aspect ratio of the image (width / height).
 * @property {Array} formats - An array of formats for the image.
 * @property {string} url - The URL of the image.
 * @property {string} thumbnail - The URL of the thumbnail image (either the small or the thumbnail format URL).
 */
const mapImageFormats = (image) => {
    const {data} = image;
    const {attributes: {caption, width, height, formats, url: imageUrl}} = data;
    const {thumbnail = null, small = null, medium = null, large = null} = formats;
    const formatArray = [thumbnail, small, medium, large, {
        width,
        height,
        url: imageUrl
    }].filter(format => format !== null);
    return {
        caption,
        aspectRatio: width / height,
        formats: formatArray,
        url: imageUrl,
        thumbnail: small ? small.url : thumbnail.url,
    }
}

/**
 * Maps the video data attribute URL from the given payload.
 *
 * @param {Object} payload - The payload containing the video data.
 * @return {string} - The URL of the video.
 */
const mapVideoData = (payload) => {
    const {data: {attributes: {url}}} = payload;
    return url;
}

/**
 * Retrieves the latest content version of an item.
 *
 * @param {Object} item - The item object containing the ID, versions, and version number.
 * @returns {Object} - Returns an object with the latest content version and update status.
 */
const getLatestContentVersion = (item) => {
    const {id, attributes: {versions, versionNumber}} = item;
    if (versions?.data?.length) {
        const {data: versionsData} = versions;
        if (versionsData?.length && versionNumber < versionsData[0]?.attributes?.versionNumber) {
            return {update: true, ...versionsData[0], id};
        }
    }
    return {update: false, ...item};
}

export {mapImageData, mapImageFormats, mapVideoData, getLatestContentVersion};
